<template>
  <div>
    <b-form class="contactinfo" @submit.prevent>
      <b-row class="form-section">
        <b-col>
          <p>
            {{ trans('prospects.landingpage.contactinfo_block.companyinfo') }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row class="mb-3">
            <b-col cols="2">
              <label class="font-weight-normal">
                {{ trans('global.companylogo') }}
              </label>
            </b-col>
            <b-col cols="4">
              <div v-if="!companyLogo" class="companylogo-container">
                <div v-if="uploadLogoStatus !== false" class="image-progress">
                  <b-progress :value="Math.round(uploadLogoStatus)" variant="primary" striped :animated="true" show-progress></b-progress>
                </div>

                <i class="fas fa-file-image" style="font-size: 40px;"></i>
                <span>{{ trans('prospects.landingpage.contactinfo_block.upload_companylogo') }}</span>
              </div>
              <div v-else class="companylogo-container noimg">
                <div v-if="uploadLogoStatus !== false" class="image-progress">
                  <b-progress :value="Math.round(uploadLogoStatus)" variant="primary" striped :animated="true" show-progress></b-progress>
                </div>
                <b-img :src="companyLogo" class="companylogo"></b-img>
              </div>
              <b-form-file
                id="prospect-companylogo"
                :placeholder="trans('projects.image_placeholder')"
                class="margin-top-16"
                accept="image/jpeg, image/png"
                @change="upload($event.target.files[0])"
              >
              </b-form-file>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="2">
              <label class="font-weight-normal">
                {{ trans('global.companyname') }}
              </label>
            </b-col>
            <b-col cols="4">
              <b-form-input
                id="prospect-company-name"
                v-model="formData.company.name"
              >
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3 center-text">
            <b-col cols="2">
              <label class="font-weight-normal">
                {{ trans('global.slogan') }}
              </label>
            </b-col>
            <b-col cols="4">
              <b-form-input
                id="prospect-company-slogan"
                v-model="formData.company.slogan"
              >
              </b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="form-section">
        <b-col style="margin-bottom:0;">
          <p>
            {{ trans('prospects.landingpage.contactinfo_block.contacts') }}
          </p>
        </b-col>
      </b-row>
      <b-row v-if="Object.keys(contactsData).length > 0">
        <b-col>
          <div v-for="(contact, contactKey, i) in contactsData" :key="i">
            <b-row class="mb-3 center-text">
              <b-col cols="12" class="miniheader">
                <div class="wrp">
                  <span>{{ trans('prospects.landingpage.contactinfo_block.contactinfo_for_person_number', { personid: parseInt(i) + 1 }) }}</span>
                  <b-button variant="danger" size="sm" @click="deleteContact(contactKey)">
                    <i class="far fa-trash-alt"></i>
                  </b-button>
                </div>
              </b-col>
              <b-col cols="2">
                <label :for="'id-' + i + 'prospect-companylogo'" class="font-weight-normal">
                  {{ trans('global.profileimage') }}
                </label>
              </b-col>
              <b-col cols="2">
                <div v-if="!profileImage[contactKey]" class="contact-profile-image-container">
                  <div v-if="uploadProfileImageStatus[contactKey]" class="image-progress">
                    <b-progress :value="Math.round(uploadProfileImageStatus[contactKey])" variant="primary" striped :animated="true" show-progress></b-progress>
                  </div>

                  <i class="fas fa-file-image" style="font-size: 40px;"></i>
                  <span>{{ trans('prospects.landingpage.contactinfo_block.upload_profileimage') }}</span>
                </div>
                <div v-else class="contact-profile-image-container noimg">
                  <div v-if="uploadProfileImageStatus[contactKey]" class="image-progress">
                    <b-progress :value="Math.round(uploadProfileImageStatus[contactKey])" variant="primary" striped :animated="true" show-progress></b-progress>
                  </div>
                  <div class="profilewrp" :style="`background-image: url(${profileImage[contactKey]})`"></div>
                </div>
                <b-form-file
                  :id="'id-' + i + 'prospect-companylogo'"
                  :placeholder="trans('projects.image_placeholder')"
                  class="margin-top-16"
                  accept="image/jpeg, image/png"
                  @change="upload($event.target.files[0], contactKey)"
                >
                </b-form-file>
              </b-col>
            </b-row>

            <b-row class="mb-3 center-text">
              <b-col cols="2">
                <label :for="'id-' + i + 'contact-name'" class="font-weight-normal">
                  {{ trans('global.name') }}
                </label>
              </b-col>
              <b-col cols="4">
                <b-form-input
                  :id="'id-' + i + 'contact-name'"
                  v-model="contactsData[contactKey].name"
                >
                </b-form-input>
              </b-col>
            </b-row>

            <b-row class="mb-3 center-text">
              <b-col cols="2">
                <label :for="'id-' + i + 'contact-position'" class="font-weight-normal">
                  {{ trans('global.position') }}
                </label>
              </b-col>
              <b-col cols="4">
                <b-form-input
                  :id="'id-' + i + 'contact-position'"
                  v-model="contactsData[contactKey].position"
                >
                </b-form-input>
              </b-col>
            </b-row>

            <b-row v-if="Object.keys(contactsData[contactKey].phone).length > 0" class="mb-1 center-text">
              <b-col cols="2">
                <label class="font-weight-normal">
                  {{ trans('global.phonenumber') }}
                </label>
              </b-col>
              <b-col>
                <b-row>
                  <b-col v-for="(phone, phoneKey, pIndex) in contactsData[contactKey].phone" :key="pIndex" cols="10" class="phonenumber">
                    <b-row class="align-content-start">
                      <b-col cols="3">
                        <b-form-input
                          :id="'contactKey-' + contactKey + '-phoneKey-' + phoneKey + '-number'"
                          v-model="contactsData[contactKey].phone[phoneKey].number"
                        >
                        </b-form-input>
                      </b-col>
                      <b-col class="col-auto">
                        <b-form-radio-group
                          v-model="contactsData[contactKey].phone[phoneKey].type"
                          :options="phoneOptions"
                          :name="'contactKey-' + contactKey + '-phoneKey-' + phoneKey + '-radio'"
                        >
                        </b-form-radio-group>
                      </b-col>
                      <b-col>
                        <b-button variant="danger" size="sm" @click="deletePhone(contactKey, phoneKey)">
                          <i class="far fa-trash-alt"></i>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col>
                <p>{{ trans('prospects.landingpage.contactinfo_block.no_phonenumbers') }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="offset-2">
                <b-button variant="primary" size="sm" @click="addPhone(contactKey)">
                  <span>{{ trans('prospects.landingpage.contactinfo_block.add_phone') }}</span>
                </b-button>
              </b-col>
            </b-row>

            <b-row v-if="Object.keys(contactsData[contactKey].email).length > 0" class="mb-1 center-text">
              <b-col cols="2">
                <label :for="'id-' + i + 'contact-emails'" class="font-weight-normal">
                  {{ trans('global.emails') }}
                </label>
              </b-col>
              <b-col>
                <b-row>
                  <b-col v-for="(email, emailKey, eIndex) in contactsData[contactKey].email" :key="eIndex + '-email'" cols="10" class="phonenumber">
                    <b-row class="align-content-start">
                      <b-col cols="3">
                        <b-form-input
                          :id="'contactKey-' + contactKey + '-emailKey-' + emailKey + '-email'"
                          v-model="contactsData[contactKey].email[emailKey]"
                        >
                        </b-form-input>
                      </b-col>
                      <b-col>
                        <b-button variant="danger" size="sm" @click="deleteEmail(contactKey, emailKey)">
                          <i class="far fa-trash-alt"></i>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col>
                <p>{{ trans('prospects.landingpage.contactinfo_block.no_emails_found') }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="offset-2">
                <b-button variant="primary" size="sm" @click="addEmail(contactKey)">
                  <span>{{ trans('prospects.landingpage.contactinfo_block.add_email') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="margin-top-26">
        <b-col cols="8" class="offset-2">
          <div class="alert alert-info">
            <i class="far fa-info-circle padding-right-6"></i> {{ trans('prospects.landingpage.contactinfo_block.no_contacts_found') }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button variant="outline-primary" size="md" class="margin-top-26 mx-auto my-5" @click="addContact">
            <span>{{ trans('prospects.landingpage.contactinfo_block.add_contact_button') }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="form-section">
        <b-col style="margin-bottom:0;">
          <p>
            {{ trans('prospects.landingpage.contactinfo_block.links') }}
          </p>
        </b-col>
      </b-row>
      <b-row v-if="Object.keys(formData.links).length > 0">
        <b-col>
          <b-row v-for="(link, linkKey, i) in formData.links" :key="i + '-link'" cols="10" class="phonenumber">
            <b-col cols="12" class="miniheader">
              <div class="wrp">
                <span>{{ trans('prospects.landingpage.contactinfo_block.linkinfo_for_link_number', { linkid: parseInt(i) + 1 }) }}</span>
                <b-button variant="danger" size="sm" @click="deleteLink(linkKey)">
                  <i class="far fa-trash-alt"></i>
                </b-button>
              </div>
            </b-col>
            <b-col cols="12">
              <b-row class="mb-3 center-text">
                <b-col cols="2">
                  <label :for="'id-' + i + 'link-title'" class="font-weight-normal">
                    {{ trans('global.title') }}
                  </label>
                </b-col>
                <b-col cols="4">
                  <b-form-input
                    :id="'id-' + i + 'link-title'"
                    v-model="formData.links[linkKey].title"
                    :placeholder="trans('prospects.landingpage.contactinfo_block.link_title_placeholder')"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-3 center-text">
                <b-col cols="2">
                  <label :for="'id-' + i + 'link-href'" class="font-weight-normal">
                    {{ trans('prospects.landingpage.contactinfo_block.link_address') }}
                  </label>
                </b-col>
                <b-col cols="4">
                  <b-form-input
                    :id="'id-' + i + 'link-href'"
                    v-model="formData.links[linkKey].href"
                    :placeholder="trans('prospects.landingpage.contactinfo_block.link_href_placeholder')"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else class="margin-top-26">
        <b-col cols="8" class="offset-2">
          <div class="alert alert-info">
            <i class="far fa-info-circle padding-right-6"></i> {{ trans('prospects.landingpage.contactinfo_block.no_links_found') }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button variant="outline-primary" size="md" class="margin-top-26 mx-auto my-5" @click="addLinks">
            <span>{{ trans('prospects.landingpage.contactinfo_block.add_links_button') }}</span>
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-center">
          <b-button
            v-if="!saveInProgress"
            ref="salestaskblockSaveButton"
            variant="primary"
            size="lg"
            class="margin-top-26 mx-auto my-5"
            @click="save"
          >
            {{ trans('prospects.landingpage.intro_block.save') }}
          </b-button>
          <b-button v-else variant="primary" size="lg" class="margin-top-26 mx-auto my-5" :style="{ width: saveButtonWidth }">
            <b-spinner variant="white" />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  /*
    Logo og firmainfo
    - Logo
    - Navn på firma
    - Slogan (kort)

    Kontaktpersoner / meglere (Object - flere personer)
    - navn (required)
    - epost (mulighet for flere)
    - telefon (type: Mobil, Telefon. Object - mulighet for flere)
    - bilde (Object - save "history")

    Valgfrie linker
    - navn på link
    - addresse

   */

  import { mapState } from 'vuex'

  const cloneDeep = require('clone-deep')
  const deepMerge = require('deepmerge')
  const fb = require('../../../../../../../firebaseConfig')

  export default {
    name: 'LandingpageBlockContactinfo',
    props: {
      block: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        uploadLogoStatus: false, // single
        uploadProfileImageStatus: {}, // multiple
        progress: 0,
        formData: {
          company: this.block.data !== undefined && this.block.data.company !== undefined ? this.block.data.company : {},
          // contacts: this.block.data !== undefined && this.block.data.contacts !== undefined ? this.block.data.contacts : {},
          links: this.block.data !== undefined && this.block.data.links !== undefined ? this.block.data.links : {}
        },
        newPhones: {},
        newEmails: {},
        saveInProgress: false,
        saveButtonWidth: false,
        unsavedContactsData: {}
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      }),
      phoneOptions () {
        return [
          { text: this.trans('global.phone'), value: 0 },
          { text: this.trans('global.mobile'), value: 1 }
        ]
      },
      companyLogo () {
        let currentCompanylogoKey = false

        if (this.block.data !== undefined && this.block.data.company !== undefined) {
          currentCompanylogoKey = this.block.data.company.logo !== undefined &&
            Object.keys(this.block.data.company.logo).length > 0
            ? Object.keys(this.block.data.company.logo).filter(logo => logo.deleted === undefined || logo.deleted !== true).pop()
            : false
        }

        return currentCompanylogoKey ? process.env.VUE_APP_IMAGE_CDN_URL + '/' +
          this.block.data.company.logo[currentCompanylogoKey].path + '/' +
          this.block.data.company.logo[currentCompanylogoKey].file
          : false
      },
      profileImage () {
        const imageUrls = {}

        if (this.block.data !== undefined && this.block.data.contacts !== undefined) {
          Object.keys(this.block.data.contacts).map((contactKey) => {
            if (this.block.data.contacts[contactKey].image !== undefined) {
              const imageKey = Object.keys(this.block.data.contacts[contactKey].image)
                .filter(image => image.deleted === undefined || image.deleted !== true)
                .pop()

              if (imageKey !== undefined) {
                imageUrls[contactKey] = process.env.VUE_APP_IMAGE_CDN_URL + '/' +
                  this.block.data.contacts[contactKey].image[imageKey].path + '/' +
                  this.block.data.contacts[contactKey].image[imageKey].file
              } // else - no image
            } // else - no image
          })
        }

        return imageUrls
      },
      // contactsData () : filters out the deleted contacts, handles new contacts and phonenumbers
      contactsData () {
        const dbContacts = {}
        console.log('contactsData generating...')

        // Add contacts-data from the DB
        if (this.block.data !== undefined && this.block.data.contacts !== undefined) {
          // Filter out the deleted contacts
          Object.keys(this.block.data.contacts).map((contactKey) => {
            if (this.block.data.contacts[contactKey] !== undefined &&
              (this.block.data.contacts[contactKey].deleted === undefined ||
                this.block.data.contacts[contactKey].deleted !== true)) {
              // remove reacitvity to the this.block prop!
              // using native JSON functions / cloneDeep removes reactivity so we can clone an object without mutating
              // the original source
              dbContacts[contactKey] = cloneDeep(this.block.data.contacts[contactKey])
            }
          })
        }

        // Merge the usaved formdata with the DB data
        const contacts = deepMerge(dbContacts, this.unsavedContactsData)
        const dynamicSubFields = ['phone', 'email']

        dynamicSubFields.map((fieldName) => {
          Object.keys(contacts).map((contactKey) => {
            let fields = {}
            if (contacts !== undefined &&
              contacts[contactKey] !== undefined &&
              contacts[contactKey][fieldName] !== undefined) {
              // Filter out all deleted fieldNamenumbers
              Object.keys(contacts[contactKey][fieldName])
                .filter(fieldNameKey =>
                  contacts[contactKey][fieldName][fieldNameKey].deleted === undefined ||
                  contacts[contactKey][fieldName][fieldNameKey].deleted !== true
                )
                // eslint-disable-next-line no-return-assign
                .forEach(fieldNameKey => fields[fieldNameKey] = contacts[contactKey][fieldName][fieldNameKey])
            }

            // Phonenumbers (new) -> Make this dynamic!
            if (fieldName === 'phone' && this.newPhones[contactKey] !== undefined && Object.keys(this.newPhones[contactKey]).length > 0) {
              fields = deepMerge(this.newPhones[contactKey], fields)
            }

            // Emails (new) -> Make this dynamic!
            if (fieldName === 'email' && this.newEmails[contactKey] !== undefined && Object.keys(this.newEmails[contactKey]).length > 0) {
              fields = deepMerge(this.newEmails[contactKey], fields)
            }

            // If no numbers exists, show atleast one empty field ready for input
            if (fieldName === 'phone' && Object.keys(fields).length === 0) {
              fields = this.addPhone(contactKey, 0) // fields =  { 0: { number: '', type: 0 }}
            }

            // If no numbers exists, show atleast one empty field ready for input
            if (fieldName === 'email' && Object.keys(fields).length === 0) {
              fields = this.addEmail(contactKey, 0) // fields =  { 0: '' }
            }

            contacts[contactKey][fieldName] = fields
          })
        })

        return contacts
      }
    },
    methods: {
      addLinks () {
        const nextLinkKeyInDB = Object.keys(this.formData.links).length > 0
          ? parseInt(Object.keys(this.formData.links).pop()) + 1
          : 0

        this.$set(this.formData.links, nextLinkKeyInDB, { title: '', href: '' })
      },
      addContact () {
        console.log('Adding contact to the form')
        const self = this

        // Saving new formdata to unsavedContactsData so we can merge it in the contactsData variable later
        this.unsavedContactsData = this.contactsData

        const nextContactKeyInDB = this.block.data.contacts !== undefined &&
          Object.keys(this.block.data.contacts).length > 0
          ? parseInt(Object.keys(this.block.data.contacts).pop()) + 1
          : 0

        fb.prospectsCollection.doc(this.prospect._id).update({
          [`landingpage.blocks.${self.block.key}.data.contacts.${nextContactKeyInDB}`]: {
            id: nextContactKeyInDB,
            createdOn: new Date(),
            deleted: false,
            email: {},
            image: {},
            jobtitle: '',
            name: '',
            phone: {},
            userId: self.userProfile.uid
          }
        }).then(() => {
          self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.add_contact_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.add_contact_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          console.log('Error while creating contactperson:')
          console.log(err)
        })
      },
      addPhone (contactId, phoneKey) {
        if (phoneKey === undefined) {
          const lastPhoneKeyInDB = this.block.data !== undefined &&
            this.block.data.contacts !== undefined &&
            this.block.data.contacts[contactId] !== undefined &&
            this.block.data.contacts[contactId].phone !== undefined &&
            Object.keys(this.block.data.contacts[contactId].phone).length > 0
            ? parseInt(Object.keys(this.block.data.contacts[contactId].phone).pop())
            : 0

          const newUnsavedPhones = this.newPhones[contactId] !== undefined &&
            Object.keys(this.newPhones[contactId]).length > 0
            ? Object.keys(this.newPhones[contactId]).length
            : 0

          phoneKey = (lastPhoneKeyInDB + newUnsavedPhones) + 1 // Ensures a unique incremented ID
        }

        const contactPhones = this.newPhones[contactId] !== undefined ? this.newPhones[contactId] : {}
        contactPhones[phoneKey] = { number: '', type: 0 }
        this.newPhones = deepMerge(this.newPhones, { [contactId]: contactPhones }) // Instead of this.$set (for reactivity)
        return { [phoneKey]: { number: '', type: 0 } }
      },
      addEmail (contactId, emailKey) {
        if (emailKey === undefined) {
          const lastEmailKeyInDB = this.block.data !== undefined &&
            this.block.data.contacts !== undefined &&
            this.block.data.contacts[contactId] !== undefined &&
            this.block.data.contacts[contactId].email !== undefined &&
            Object.keys(this.block.data.contacts[contactId].email).length > 0
            ? parseInt(Object.keys(this.block.data.contacts[contactId].email).pop())
            : 0

          const newUnsavedEmails = this.newEmails[contactId] !== undefined &&
            Object.keys(this.newEmails[contactId]).length > 0
            ? Object.keys(this.newEmails[contactId]).length
            : 0

          emailKey = (lastEmailKeyInDB + newUnsavedEmails) + 1 // Ensures a unique incremented ID
        }

        const contactEmails = this.newEmails[contactId] !== undefined ? this.newEmails[contactId] : {}
        contactEmails[emailKey] = ''
        this.newEmails = deepMerge(this.newEmails, { [contactId]: contactEmails }) // Instead of this.$set (for reactivity)
        return { [emailKey]: '' }
      },
      deleteContact (contactKey) {
        const self = this

        this.$bvModal.msgBoxConfirm(this.trans('prospects.landingpage.contactinfo_block.delete_confirm', { name: self.trans('prospects.landingpage.contactinfo_block.contact_person') }), {
          title: this.trans('prospects.landingpage.contactinfo_block.delete_confirm_title'),
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: this.trans('global.delete'),
          cancelTitle: this.trans('global.cancel')
        }).then((value) => {
          if (value !== false) {
            if (self.block.data.contacts[contactKey] !== undefined) {
              fb.prospectsCollection.doc(this.prospect._id).update({
                [`landingpage.blocks.${self.block.key}.data.contacts.${contactKey}.deleted`]: true
              }).then(() => {
                self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_success'), {
                  title: self.trans('global.success'),
                  variant: 'success',
                  solid: true
                })
              }).catch((err) => {
                self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_error'), {
                  title: self.trans('global.error'),
                  variant: 'warning',
                  solid: true
                })
                console.log('Error while deleting contact')
                console.log(err)
              })
            } else {
              console.log('The contact record could not be found in DB or object...')
            }
          } else {
            console.log('Delete was aborted')
          }
        }).catch((err) => {
          console.log('Error')
          console.log(err)
        })
      },
      deletePhone (contactKey, phoneKey) {
        const self = this

        this.$bvModal.msgBoxConfirm(this.trans('prospects.landingpage.contactinfo_block.delete_number_confirm', { number: self.trans('prospects.landingpage.contactinfo_block.phone_number') }), {
          title: this.trans('prospects.landingpage.contactinfo_block.delete_confirm_title'),
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: this.trans('global.delete'),
          cancelTitle: this.trans('global.cancel')
        }).then((value) => {
          if (value !== false) {
            // Check if phone-number is stored in this.newPhones, else remove from DB
            if (this.newPhones[contactKey] !== undefined &&
              this.newPhones[contactKey][phoneKey] !== undefined) {
              delete this.newPhones[contactKey][phoneKey]
              this.newPhones = Object.assign({}, this.newPhones) // Instead of this.$set (for reactivity)

              self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_number_success'), {
                title: self.trans('global.success'),
                variant: 'success',
                solid: true
              })
            } else if (self.block.data.contacts[contactKey].phone[phoneKey] !== undefined) {
              fb.prospectsCollection.doc(this.prospect._id).update({
                [`landingpage.blocks.${self.block.key}.data.contacts.${contactKey}.phone.${phoneKey}.deleted`]: true
              }).then(() => {
                self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_number_success'), {
                  title: self.trans('global.success'),
                  variant: 'success',
                  solid: true
                })
              }).catch((err) => {
                self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_number_error'), {
                  title: self.trans('global.error'),
                  variant: 'warning',
                  solid: true
                })
                console.log('Error while deleting contact')
                console.log(err)
              })
            } else {
              console.log('Could not find the phoneKey in DB or object...')
            }
          } else {
            console.log('Delete was aborted')
          }
        }).catch((err) => {
          console.log('Error')
          console.log(err)
        })
      },
      deleteEmail (contactKey, emailKey) {
        const self = this

        this.$bvModal.msgBoxConfirm(this.trans('prospects.landingpage.contactinfo_block.delete_email_confirm'), {
          title: this.trans('prospects.landingpage.contactinfo_block.delete_email_confirm_title'),
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: this.trans('global.delete'),
          cancelTitle: this.trans('global.cancel')
        }).then((value) => {
          if (value !== false) {
            // Check if phone-number is stored in this.newPhones, else remove from DB
            if (this.newEmails[contactKey] !== undefined &&
              this.newEmails[contactKey][emailKey] !== undefined) {
              delete this.newEmails[contactKey][emailKey]
              this.newEmails = Object.assign({}, this.newEmails) // Instead of this.$set (for reactivity)

              self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_email_success'), {
                title: self.trans('global.success'),
                variant: 'success',
                solid: true
              })
            } else if (self.block.data.contacts[contactKey].email[emailKey] !== undefined) {
              fb.prospectsCollection.doc(this.prospect._id).update({
                [`landingpage.blocks.${self.block.key}.data.contacts.${contactKey}.email.${emailKey}`]: fb.firebase.firestore.FieldValue.delete()
              }).then(() => {
                self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_email_success'), {
                  title: self.trans('global.success'),
                  variant: 'success',
                  solid: true
                })
              }).catch((err) => {
                self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_email_error'), {
                  title: self.trans('global.error'),
                  variant: 'warning',
                  solid: true
                })
                console.log('Error while deleting email')
                console.log(err)
              })
            } else {
              console.log('Could not find the phoneKey in DB or object...')
            }
          } else {
            console.log('Delete was aborted')
          }
        }).catch((err) => {
          console.log('Error')
          console.log(err)
        })
      },
      deleteLink (linkKey) {
        const self = this

        this.$bvModal.msgBoxConfirm(this.trans('prospects.landingpage.contactinfo_block.delete_email_confirm'), {
          title: this.trans('prospects.landingpage.contactinfo_block.delete_email_confirm_title'),
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: this.trans('global.delete'),
          cancelTitle: this.trans('global.cancel')
        }).then((value) => {
          if (value !== false) {
            if (self.block.data.links !== undefined && self.block.data.links[linkKey] !== undefined) {
              fb.prospectsCollection.doc(this.prospect._id).update({
                [`landingpage.blocks.${self.block.key}.data.links.${linkKey}.deleted`]: true
              }).then(() => {
                self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_link_success'), {
                  title: self.trans('global.success'),
                  variant: 'success',
                  solid: true
                })
              }).catch((err) => {
                self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_link_error'), {
                  title: self.trans('global.error'),
                  variant: 'warning',
                  solid: true
                })
                console.log('Error while deleting email')
                console.log(err)
              })
            } else if (self.formData.links[linkKey] !== undefined) {
              self.$delete(self.formData.links, linkKey)

              self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.delete_link_success'), {
                title: self.trans('global.success'),
                variant: 'success',
                solid: true
              })
            } else {
              console.log('Could not find the phoneKey in DB or object...')
            }
          } else {
            console.log('Delete was aborted')
          }
        }).catch((err) => {
          console.log('Error')
          console.log(err)
        })
      },
      save () {
        console.log('Saving from ContactInfo')
        // Fix all new phone-KEYs for each contact to updated valid keys
        const validPhoneKeys = {}
        Object.keys(this.newPhones).map((contactKey) => {
          if (Object.keys(this.newPhones[contactKey]).length > 0 &&
            this.block.data !== undefined && this.block.data.contacts !== undefined
          ) {
            validPhoneKeys[contactKey] = this.block.data.contacts[contactKey].phone
            let nextPhoneKeyInDB = this.block.data.contacts[contactKey] !== undefined &&
              this.block.data.contacts[contactKey].phone !== undefined &&
              Object.keys(this.block.data.contacts[contactKey].phone).length > 0
              ? parseInt(Object.keys(this.block.data.contacts[contactKey].phone).pop()) + 1
              : 0

            Object.keys(this.newPhones[contactKey]).map((tmpPhoneKey) => {
              validPhoneKeys[contactKey][nextPhoneKeyInDB] = this.newPhones[contactKey][tmpPhoneKey]
              nextPhoneKeyInDB++
            })

            this.block.data.contacts[contactKey].phone = validPhoneKeys[contactKey] // Update the prop itself...
            nextPhoneKeyInDB = 0
          }
        })

        // let saveData = this.block.data !== undefined ? this.block.data : {}
        // if (this.formData !== undefined && this.formData.company !== undefined) {
        //   saveData.company = { ...this.block.data.company, ...this.formData.company }
        // }
        // if (this.formData !== undefined && this.formData.links !== undefined) {
        //   saveData.links = {...this.block.data.links, ...this.formData.links }
        // }
        // saveData.contacts = {...this.block.data.contacts, ...this.contactsData }

        const blockData = this.block.data !== undefined ? this.block.data : {}
        const formData = this.formData !== undefined ? this.formData : {}
        const saveData = deepMerge(blockData, formData) // Merge new formdata with old blockdata

        // this.contactsData is a computed property with both old and new info - without deleted ones, so merge them!
        saveData.contacts = blockData.contacts !== undefined
          ? deepMerge(blockData.contacts, this.contactsData)
          : this.contactsData

        const self = this
        this.saveButtonWidth = this.$refs.salestaskblockSaveButton ? (parseInt(this.$refs.salestaskblockSaveButton.clientWidth) + 2).toString() + 'px' : false
        this.saveInProgress = true

        fb.prospectsCollection.doc(this.prospect._id).update({
          [`landingpage.blocks.${this.block.key}.data`]: saveData
        }).then(() => {
          self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.save_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
          this.saveInProgress = false
          this.newPhones = {}
          this.newEmails = {}
          this.unsavedContactsData = {}
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.landingpage.contactinfo_block.save_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          console.log('There was an error while saving the info for the Salestask-block to the DB:')
          console.log(err)
          this.saveInProgress = false
          this.newPhones = {}
          this.newEmails = {}
          this.unsavedContactsData = {}
        })
      },
      upload (imageFile, contactKey = false) {
        const self = this

        if (imageFile !== undefined && imageFile.name !== undefined) {
          if (contactKey === false) {
            this.uploadLogoStatus = 1
          } else {
            this.$set(this.uploadProfileImageStatus, contactKey, 1)
          }

          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: imageFile.type
          }

          const orgFilename = imageFile.name
          const newFilename = this.randomTxt(16) + '.' + imageFile.name.split('.').pop()

          const imageTargetPath = 'projects/' + this.project.varycode + '/prospects/' + this.prospect.varycode +
            '/landingpage/block-' + this.block.key + (contactKey === false ? '/companylogo' : '/contacts')

          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(imageFile, metadata)

          uploadTask.on('state_changed', function (snapshot) {
            if (contactKey === false) {
              self.uploadLogoStatus = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            } else {
              self.$set(self.uploadProfileImageStatus, contactKey, (snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            }

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
            }
          }, function (error) {
            let err = ''
            switch (error.code) {
              case 'storage/unauthorized':
                err = 'Storage autorization required, access denied...'
                break
              case 'storage/canceled':
                err = 'The upload was cancelled'
                break
              case 'storage/unknown':
                err = 'An unknown error occurred during upload'
                break
            }
            console.log(err)
          }, function () {
            let nextImageKey = false

            if (contactKey === false) { // companyLogo
              self.uploadLogoStatus = false
              nextImageKey = self.block.data !== undefined &&
                self.block.data.company !== undefined &&
                self.block.data.company.logo !== undefined &&
                Object.keys(self.block.data.company.logo).length > 0
                ? parseInt(Object.keys(self.block.data.company.logo).pop()) + 1
                : 0
            } else { // contactProfileImage
              delete self.uploadProfileImageStatus[contactKey]

              if (!(contactKey in self.block.data.contacts)) { // Check if the key is available, if not, increment
                contactKey = self.block.data.contacts !== undefined &&
                  Object.keys(self.block.data.contacts).length > 0
                  ? parseInt(Object.keys(self.block.data.contacts).pop()) + 1
                  : 0
              } // else -> existing contact - use preset contactKey

              nextImageKey = self.block.data.contacts !== undefined &&
                self.block.data.contacts[contactKey] !== undefined &&
                self.block.data.contacts[contactKey].image !== undefined &&
                Object.keys(self.block.data.contacts[contactKey].image).length > 0
                ? parseInt(Object.keys(self.block.data.contacts[contactKey].image).pop()) + 1
                : 0
            }

            const query = contactKey === false
              ? `landingpage.blocks.${self.block.key}.data.company.logo.${nextImageKey}`
              : `landingpage.blocks.${self.block.key}.data.contacts.${contactKey}.image.${nextImageKey}`

            fb.prospectsCollection.doc(self.prospect._id).update({
              [query]: {
                id: nextImageKey,
                createdOn: new Date(),
                userId: self.userProfile.uid,
                deleted: false,
                file: newFilename,
                orgFile: orgFilename,
                path: imageTargetPath,
                metadata
              }
            }).then(() => {
              if (contactKey === false) {
                // Force update the formData with the new data
                self.$set(self.formData, 'company', self.block.data.company)
              }
            })
          })
        } else {
          this.uploadStatus = 1
          console.log('[ERROR] Imagefile was not found')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .contactinfo {
    .row {
      .center-text {
        align-items: center;

        label {
          margin-bottom: 0;
        }
      }

      .miniheader {
        background: #e7e7e7;
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > * {
            flex: 0 0 auto;
          }
        }
      }

      .companylogo-container {
        position: relative;
        display: flex;
        background-color: rgba(85,25,65,.15);
        border-radius: 4px;
        width: 100%;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        color: #B5BCC6;

        i {
          margin-right: 30px;
        }

        .image-progress {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;

          margin-top: -8px;

          transition: opacity .5s;

          .progress {
            background-color: #f3f6f9;
          }
        }

        .companylogo {
          max-width: 200px;
          max-height: 200px;
        }
      }

      .contact-profile-image-container {
        position: relative;
        display: flex;
        background-color: rgba(85,25,65,.15);
        border-radius: 4px;
        width: 100%;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        color: #B5BCC6;

        padding: 20px;

        i {
          margin-right: 30px;
        }

        .image-progress {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          z-index: 4;

          margin-top: -8px;

          transition: opacity .5s;

          .progress {
            background-color: #f3f6f9;
          }
        }

        .profilewrp {
          height: 0;
          overflow: hidden;
          padding-top: 100%;
          position: relative;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          width: 100%;
          border-radius: 50%;
        }
      }

      .phonenumber {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
